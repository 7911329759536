.swiper-button-prev:after,
.swiper-button-next:after {
	color: #fff;
	text-shadow: 0 0 5px #000;
	font-size: 1.5rem !important;
	z-index: 10;
}

.swiper-button-prev:after {
	transform: translateX(-30%);
}

.swiper-button-next:after {
	transform: translateX(30%);
}

.swiper-button-disabled:after {
	display: none;
}

@media (max-width: 500px) {
	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 1.2rem !important;
	}
}

