.loader-container{
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #FFF;
}

.label-loader {
	font-size: 20px;
	font-family: 'Ubuntu', sans-serif;
	color: black;
	font-weight: 500;
	padding-bottom: 20px;
	text-align: center;
}

.loader {
	animation: is-rotating 1s infinite;
	border: 6px solid rgba(165,90,204, 0.5);
	border-radius: 50%;
	border-top-color: var(--mainTheme);
	height: 50px;
	width: 50px;
  }
  
  @keyframes is-rotating {
	to {
	  transform: rotate(1turn);
	}
  }