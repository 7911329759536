.loader-container-general{
    display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
}

.loader-general{
	animation: is-rotating 1s infinite;
	border: 6px solid #e5e5e5;
	border-radius: 50%;
	border-top-color: gray;
	height: 30px;
	width: 30px;
  }
  
  @keyframes is-rotating {
	to {
	  transform: rotate(1turn);
	}
  }